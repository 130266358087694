import * as React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../components/Layout"
import Seo from "../components/seo"

const IndexPage = ({ location }) => (
  <Layout location={location}>
    <Seo title="K&B - Kitchen and Bathroom" />
    <div className="columns has-text-centered">
      <div className="column is-4">
      <div className="p-4"><StaticImage src="../img/issue2022/Mazloom.jpg"
        alt=""/></div>
        <div className="p-4"><StaticImage src="../img/issue2022/AhmedKarim.jpg"
        alt=""/></div>
        <div className="p-4"><StaticImage src="../img/issue2022/Duravit.jpg"
        alt=""/></div>
      </div>
      <div className="column is-4 	">
      <StaticImage src="../img/2022.png"
        alt=""/>
        <p className="pt-6">
        Dear Readers & Clients, we are happy to issue this Year’s catalogue after a long time people facing Covid 19 and we hope all stay safe and in a perfect health.
This issue provides our customers with the Kitchen and Bath industry latest trends. We are the leaders in the Egyptian market The one catalogue that professionals in the field showcase their products to identify new customers and boost sales. We are the only catalogue serving the Kitchen and Bath indulgence for those looking to build up or rejuvenate these two rooms in their homes. In this essence we make sure to host the best and only the best in the world of Kitchen and Bath.
We are the source of high end finishes and for Real Estate projects to well equip these two rooms in the ultimate class that matches the beauty of the new homes to satisfy the tastes of their clients as well as offering them sustainable solutions. Our Clients wait for our Catalogue each year that is what they told us and this time we are bringing them our news that K&B Catalogue is going to be online too to help them browse all what they need in the tip of their hands.
Now we will leave you to enjoy the following pages and will be waiting for your Feedbacks.
the related Access Standards, this catalogue presents the
best practices developed by the Association›s committee of professionals through extensive research.
        </p>
      </div>
      <div className="column is-4">
      <div className="p-4"><StaticImage src="../img/issue2022/G-Group.jpg"
        alt=""/></div>
        <div className="p-4"><StaticImage src="../img/issue2022/Gemma.jpg"
        alt=""/></div>
        <div className="p-4"><StaticImage src="../img/issue2022/Hanimex.jpg"
        alt=""/></div>
      </div>
    </div>

    <div className="columns has-text-centered">
      <div className="column is-4">
        <StaticImage src="../img/issue2022/list1.png"
        alt=""/>
      </div>
      <div className="column is-4 	">
      <div className="p-4"><StaticImage src="../img/issue2022/MostafaElsallab.jpg"
        alt=""/></div>
        <div className="p-4"><StaticImage src="../img/issue2022/Royal.jpg"
        alt=""/> </div>
        <div className="p-4"><StaticImage src="../img/issue2022/SJdesign.jpg"
        alt=""/></div>
      </div>
      <div className="column is-4">
        <StaticImage src="../img/issue2022/list2.png"
        alt=""/>
      </div>
    </div>
</Layout>
)

export default IndexPage